.page_not_found {
    min-height: 100vh;
    background-image: url('../../images/404.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.accueil_404 {

    color: #ff6a66;

}
.page_non_trouvee {
  font-size: 2em;
  margin-top: 0;
  padding-top: 57px;
}



@media screen and (max-width: 768px) {
  .page_non_trouvee {
    font-size: 1.5em;
    padding-top: 77px;
   
  }
}